<template>
    <div>
        <b-row>
            <b-col lg="4">
                <b-card title="User">
                    <BasicOverview v-if="!editEnabled" :data="project" :definitions="overviewDefinitions"/>
                    <div v-else>
                        <b-form-group>
                            <label>Name</label>
                            <b-form-input v-model="editObject.name"/>
                        </b-form-group>

                        <b-form-group>
                            <label>Client</label>
                            <v-select
                                v-model="editObject.client_id"
                                :reduce="type => type.id"
                                label="name"
                                :options="clients"
                            />
                        </b-form-group>
                    </div>

                    <hr/>

                    <b-button v-if="!editEnabled" variant="warning" @click="openEdit" class="mr-1">
                        <feather-icon icon="EditIcon" class="mr-50"/>
                        <span class="align-middle">Edit</span>
                    </b-button>

                    <b-button v-if="editEnabled" variant="success" @click="saveProject" class="mr-1">
                        <feather-icon icon="SaveIcon" class="mr-50"/>
                        <span class="align-middle">Save</span>
                    </b-button>

                    <b-button v-if="editEnabled" variant="danger" @click="editEnabled = false">
                        <feather-icon icon="XIcon" class="mr-50"/>
                        <span class="align-middle">Cancel</span>
                    </b-button>

                    <b-button v-if="editEnabled" variant="danger" @click="removeProject" class="float-right mr-1">
                        <feather-icon icon="Trash2Icon" class="mr-50"/>
                        <span class="align-middle">Remove</span>
                    </b-button>
                </b-card>
            </b-col>
            <b-col lg="8">
                <ProjectFirmwareMappings :project-id="$route.params.id"/>
            </b-col>
        </b-row>

    </div>
</template>
<script>

    import {BButton, BFormGroup, BFormInput, BCard, BRow, BCol} from 'bootstrap-vue'
    import BasicOverview from '@/views/components/BasicOverview'
    import vSelect from 'vue-select'
    import ProjectFirmwareMappings from '@/views/Project/ProjectFirmwareMappings'

    export default {
        components: {
            ProjectFirmwareMappings,
            BasicOverview,
            BButton,
            BFormGroup,
            BFormInput,
            BCard,
            BRow,
            BCol,
            vSelect
        },
        data() {
            return {
                dataLoaded: false,
                project: {},

                clients: [],

                overviewDefinitions: [
                    { key: 'name', name: 'Name', type: 0 },
                    { key: 'client', name: 'Client', type: 0 }
                ],

                editEnabled: false,
                editObject: {}
            }
        },
        methods: {
            openEdit() {
                this.editObject = JSON.parse(JSON.stringify(this.project))
                this.editEnabled = true
            },
            loadData() {

                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/project/${this.$route.params.id}`)
                loadPromise.then(function(response) {
                    thisIns.project = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const clientsLoadPromise = this.$http.get('/api/management/v1/client')
                clientsLoadPromise.then(function(response) {
                    thisIns.clients = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, clientsLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            saveProject() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/project/${this.$route.params.id}`, this.editObject)
                savePromise.then(function() {
                    thisIns.$printSuccess('Project saved')
                    thisIns.editEnabled = false
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            async removeProject() {

                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this project?')

                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/project/${  this.$route.params.id}`)
                    this.$printSuccess('Project removed')
                    this.$router.go(-1)

                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>