<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Firmware mappings">

                <b-button variant="black2" @click="addMappingModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="firmwareMappings" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="warning" @click="openEdit(props.row)" class="mr-1">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button variant="danger" @click="removeMapping(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <div v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <span v-if="props.row.terminal_model_type === 0"><feather-icon size="22" icon="HardDriveIcon" class="mr-1"/>  {{ props.formattedRow[props.column.field] }} </span>
                        <span v-else><feather-icon size="22" icon="MonitorIcon" class="mr-1"/>  {{ props.formattedRow[props.column.field] }} </span>
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>


        <b-modal title="Add mapping" v-model="addMappingModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Terminal model</label>
                    <v-select
                        v-model="addObject.terminal_model_id"
                        :reduce="terminalModel => terminalModel.id"
                        label="name"
                        :options="terminalModels"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Firmware package</label>
                    <v-select
                        v-model="addObject.firmware_package_id"
                        :reduce="firmwarePackage => firmwarePackage.id"
                        label="version"
                        :options="firmwarePackages.filter(firmwarePackage => firmwarePackage.terminal_model_id === addObject.terminal_model_id)"
                    />
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addMappingModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addMapping">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>


        <b-modal title="Edit mapping" v-model="editMappingModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Terminal model</label>
                    <v-select
                        v-model="editObject.terminal_model_id"
                        :reduce="terminalModel => terminalModel.id"
                        label="name"
                        disabled
                        :options="terminalModels"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Firmware package</label>
                    <v-select
                        v-model="editObject.firmware_package_id"
                        :reduce="firmwarePackage => firmwarePackage.id"
                        label="version"
                        :options="firmwarePackages.filter(firmwarePackage => firmwarePackage.terminal_model_id === editObject.terminal_model_id)"
                    />
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="editMappingModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="warning" @click="saveMapping">
                    <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
    import {BButton, BCard, BFormGroup, BModal, BOverlay} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import vSelect from 'vue-select'

    export default {
        props: {
            projectId: {
                type: String,
                required: true
            }
        },
        components: {
            BCard,
            BOverlay,
            BasicTable,
            BButton,
            BFormGroup,
            BModal,
            vSelect
        },
        data() {
            return {
                dataLoaded: false,
                firmwareMappings: [],

                firmwarePackages: [],
                terminalModels: [],

                columns: [
                    {
                        label: 'Terminal model',
                        displayType: 2,
                        field: 'terminal_model',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Terminal model codename',
                        displayType: 0,
                        field: 'terminal_model_codename',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Version',
                        displayType: 0,
                        field: 'firmware_package_version',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addMappingModalActive: false,
                addObject: {
                    terminal_model_id: '',
                    firmware_package_id: ''
                },

                editMappingModalActive: false,
                editObject: {}
            }
        },
        methods: {
            openEdit(object) {
                this.editObject = JSON.parse(JSON.stringify(object))
                this.editMappingModalActive = true
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/project/${  this.projectId  }/firmware_mapping`)
                loadPromise.then(function(response) {
                    thisIns.firmwareMappings = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const firmwarePackagesLoadPromise = this.$http.get('/api/management/v1/firmware_package')
                firmwarePackagesLoadPromise.then(function(response) {
                    thisIns.firmwarePackages = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const terminalModelsLoadPromise = this.$http.get('/api/management/v1/terminal_model')
                terminalModelsLoadPromise.then(function(response) {
                    thisIns.terminalModels = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
                
                Promise.all([loadPromise, firmwarePackagesLoadPromise, terminalModelsLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addMapping() {
                const thisIns = this
                const addPromise = this.$http.post(`/api/management/v1/project/${  this.projectId  }/firmware_mapping`, this.addObject)
                addPromise.then(function() {
                    thisIns.$printSuccess('Project Firmware mapping added')
                    thisIns.addMappingModalActive = false
                    thisIns.addObject = {
                        terminal_model_id: '',
                        firmware_package_id: ''
                    }
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })

            },
            saveMapping() {

                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/project/${  this.projectId  }/firmware_mapping/${  this.editObject.id  }`, this.editObject)
                savePromise.then(function() {
                    thisIns.$printSuccess('Project Firmware mapping saved')
                    thisIns.editMappingModalActive = false
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            removeMapping(id) {
                const thisIns = this
                const removePromise = this.$http.delete(`/api/management/v1/project/${  this.projectId  }/firmware_mapping/${  id  }`)
                removePromise.then(function() {
                    thisIns.$printSuccess('Project Firmware mapping removed')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        computed: {
            unusedTerminalModels() {
                return this.terminalModels.filter(terminalModel => this.firmwareMappings.some(mapping => terminalModel.id === mapping.terminal_model_id))
            }
        },
        mounted() {
            this.loadData()
        }

    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>